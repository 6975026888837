import PropTypes from 'prop-types';
import React from 'react';

import Section from 'ui-kit/section';
import Textlink from 'ui-kit/textlink';
import { Highlight, P } from 'ui-kit/typography';

import Logo from '../components/logo';
import Layout from '../components/layout';

const ImprintPage = ({ data }) => {
    return (
        <Layout
            description="Impressum"
            title="Impressum | Mein Bayern - Leckere Wurst von hier"
        >
            <Logo />
            <Section bg="white" container="l">
                <P gap="xxl">
                    <Highlight>Südbayerische Fleischwaren GmbH </Highlight> <br />
                    Robert-Bosch-Straße 13 <br />
                    85053 Ingolstadt{' '}
                </P>
                <P gap="xxl">
                    Tel.:{' '}
                    <Textlink
                        to="tel:+49841966340"
                        textColor="black"
                        gap="m"
                        display="inline-block"
                    >
                        +49 (0) 841 / 96 634 - 0
                    </Textlink>{' '}
                    <br />
                    Fax.:{' '}
                    <Textlink
                        to="fax:+498419663419"
                        textColor="black"
                        gap="m"
                        display="inline-block"
                    >
                        +49 (0) 841 / 96 634 - 19
                    </Textlink>{' '}
                    <br />
                    E-Mail:{' '}
                    <Textlink
                        to="email:info@suedbayerische-fleischwaren.de"
                        gap="m"
                        display="inline-block"
                    >
                        info@suedbayerische-fleischwaren.de
                    </Textlink>
                </P>
                <P gap="xxl">
                    <Highlight>Vertretungsberechtigte Geschäftsführer</Highlight>
                    <br />
                    Dr. Dirk Eßmann<br />
                    Bernhard Stadlmeier<br />
                    Oliver Heyden
                </P>
                <P gap="xxl">
                    <Highlight>Eingetragen bei</Highlight>
                    <br />
                    Handelsregister des Amtsgerichtes Ingolstadt / HRB 272
                </P>
                <P gap="xxl">
                    <Highlight>Umsatzsteuer-ID-Nr. gem. § 27a UStG</Highlight>
                    <br />
                    DE 811 118 299
                </P>
                <P gap="xxl">
                    <Highlight>Inhaltlich Verantwortlicher </Highlight>
                    <br />
                    Dr. Dirk Eßmann<br />
                    Bernhard Stadlmeier<br />
                    Oliver Heyden
                </P>
                <P>
                    <Highlight>Konzeption und Realisierung</Highlight>
                    <br />
                    <Textlink
                        to="https://www.gup-stuttgart.de"
                        gap="m"
                        display="inline-block"
                        textColor="black"
                    >
                        GuP Glanzer und Partner Werbeagentur GmbH
                    </Textlink>
                </P>
            </Section>
        </Layout>
    );
};

ImprintPage.propTypes = {
    data: PropTypes.shape({
        stageImage: PropTypes.object,
    }).isRequired,
};

export default ImprintPage;
